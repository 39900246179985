<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12 sm12>
        <v-card outlined class="primary--border">
          <v-card-title class="title">
            Details
            <v-spacer></v-spacer>
            <transition
              name="animate-css-transition"
              enter-active-class="animated fadeInRight"
              leave-active-class="animated fadeOutRight"
            >
              <add-button
                icon="check_circle"
                permission="fee-create"
                @action="verifyDialog = true"
                v-if="this.$route.query.is_journal_entry == 1 && !syncButton"
              >
                Verify Billing
              </add-button>
            </transition>
            <transition
              name="animate-css-transition"
              enter-active-class="animated fadeInRight"
              leave-active-class="animated fadeOutRight"
            >
              <add-button
                color="error"
                permission="bill-generate-verify"
                @action="unverfyDialog = true"
                v-if="this.$route.query.is_journal_entry == 1"
                style="background: red"
              >
                Cancel Verification
              </add-button>
            </transition>
          </v-card-title>
          <v-card outlined>
            <v-card-title class="title">
              <span> Grade : {{ this.$route.query.grade }}</span>
              <span style="margin-left: 50px">
                Month : {{ this.$route.query.billing_month }}</span
              >
              <v-spacer></v-spacer>
              <span> </span>
              <span v-if="this.$route.query.is_journal_entry == 0">
                <v-chip small color="primary" text-color="white"
                  >Status: Billing On Processing</v-chip
                >
              </span>
              <span v-if="this.$route.query.is_journal_entry == 1">
                <v-chip small color="warning" text-color="white"
                  >Status: Waiting For Approval</v-chip
                >
              </span>
              <span v-if="this.$route.query.is_journal_entry == 2">
                <v-chip small color="green" text-color="white"
                  >Status: Approved</v-chip
                >
              </span>
            </v-card-title>
          </v-card>

          <table
            class="v-datatable v-table theme--light"
            width="100%"
            v-if="this.accountServiceStatus"
          >
            <thead>
              <tr align="left">
                <th>Fee Head</th>
                <th>Dr</th>
                <th>Cr</th>
              </tr>
            </thead>
            <tbody>
              <tr align="left" v-for="(x, i) in form.items.data" :key="i">
                <td>
                  {{ x.fee_head }}
                  <p v-if="x.map_ledger_head" style="font-size: 12px">
                    ({{ x.map_ledger_head.asset_ledger }})
                  </p>
                  <p v-else style="color:red;">
                    <span style="display: none">{{ (syncButton = true) }}</span>
                    No maping found.
                  </p>
                </td>
                <td>{{ x.total.currency() }}</td>
                <td></td>
              </tr>
              <tr
                align="left"
                v-for="(y, i) in form.items.data"
                :key="form.items.data + i"
              >
                <td>
                  <span class="crRow"
                    >{{ y.fee_head }}
                    <p
                      class="crRow"
                      v-if="y.map_ledger_head"
                      style="font-size: 12px"
                    >
                      ({{ y.map_ledger_head.income_ledger }})
                    </p>
                    <p class="crRow" v-else style="color:red;">
                      No maping found.
                    </p>
                  </span>
                </td>
                <td></td>
                <td>{{ y.total.currency() }}</td>
              </tr>
              <tr align="left">
                <th align="left">Grand Total :</th>
                <th>{{ totalSum.currency() }}</th>
                <th>{{ totalSum.currency() }}</th>
              </tr>
            </tbody>
            <v-card-actions
              v-if="
                form.items.data.length &&
                  this.$route.query.is_journal_entry == 1
              "
            >
              <v-flex xs12 sm12>
                <textarea
                  placeholder="Narration"
                  v-model="form.narration"
                  label="Narration"
                  style="border: 1px solid #666;width: 100%;height: 80px;"
                >
                </textarea>
              </v-flex>
            </v-card-actions>
            <v-card-actions v-if="form.items.data.length">
              <v-flex xs12 sm12>
                <v-spacer></v-spacer>
                <add-button
                  icon="check_circle"
                  permission="fee-create"
                  @action="verifyDialog = true"
                  v-if="this.$route.query.is_journal_entry == 1 && !syncButton"
                >
                  Verify Billing
                </add-button>
                <add-button
                  color="error"
                  permission="bill-generate-verify"
                  @action="unverfyDialog = true"
                  v-if="this.$route.query.is_journal_entry == 1"
                  style="background: red"
                >
                  Cancel Verification
                </add-button>
              </v-flex>
            </v-card-actions>
          </table>

          <v-data-table
            v-else
            :headers="headers"
            :items="form.items.data"
            :search="search"
            :options.sync="pagination"
            :footer-props="footerProps"
            :server-items-length="form.items.meta.total"
            :loading="form.loading"
          >
            <template v-slot:item="{ index, item }">
              <tr @click="expanded = !expanded" style="cursor: pointer;">
                <td>{{ index + form.items.meta.from }}</td>
                <td class="text-xs-left">
                  {{ item.fee_head }}
                </td>
                <td class="text-xs-left">{{ item.total.currency() }}</td>
              </tr>
            </template>
            <template v-slot:body.append>
              <td :colspan="headers.length - 1">
                <strong>Total:</strong>
              </td>
              <td>{{ totalSum.currency() }}</td>
            </template>
          </v-data-table>
          <v-card-actions
            v-if="form.items.data.length && !accountServiceStatus"
          >
            <v-flex xs12 sm12>
              <v-spacer></v-spacer>
              <add-button
                icon="check_circle"
                permission="fee-create"
                @action="verifyDialog = true"
                v-if="this.$route.query.is_journal_entry == 1"
              >
                Verify Billing
              </add-button>
              <add-button
                color="error"
                permission="bill-generate-verify"
                @action="unverfyDialog = true"
                v-if="this.$route.query.is_journal_entry == 1"
                style="background: red"
              >
                Cancel Verification
              </add-button>
            </v-flex>
          </v-card-actions>
        </v-card>
      </v-flex>
      <v-dialog v-model="verifyDialog" persistent max-width="600">
        <v-card>
          <v-card-title class="title pa-3 warning white--text">
            <v-icon class="mr-2 white--text">warning</v-icon>
            Please, wait!
          </v-card-title>
          <v-card-text class="ma-0"
            >Are you sure want to approved this billing? Once approved process
            cannot be reverted.
            <p style="color: red">This will post to account as well.</p>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="success" outlined small @click="verifyDialog = false"
              >No</v-btn
            >
            <v-btn color="warning" outlined small @click="verify">Yes</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="unverfyDialog" persistent max-width="290">
        <v-card>
          <v-card-title class="title pa-3 warning white--text">
            <v-icon class="mr-2 white--text">warning</v-icon>
            Please, wait!
          </v-card-title>
          <v-card-text class="ma-0"
            >Are you sure you want to resend Billing for further processing?
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="success" outlined small @click="unverfyDialog = false"
              >No</v-btn
            >
            <v-btn color="warning" outlined small @click="unverfiy">Yes</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
  </v-container>
</template>
<script>
import { mapState } from "vuex";
import Form from "@/library/Form";
import Mixins from "@/library/Mixins";

const NepaliDate = require("nepali-date-js");
const nd = new NepaliDate();

export default {
  mixins: [Mixins],
  data: () => ({
    form: new Form(
      {
        sod_date: nd.format("YYYY-MM-DD"),
        narration: "",
        billLogId: "",
        incomeHeads: [],
        assetsHeads: [],
      },
      "/api/billing-summary"
    ),
    search: null,
    accountServiceStatus: false,
    expand: false,
    pagination: {
      rowsPerPage: 10,
    },
    verifyDialog: false,
    unverfyDialog: false,
    totalSum: 0,
    headers: [
      { text: "#", align: "center", value: "id", width: 50, sortable: false },
      { text: "Fee Head", align: "left", value: "fee_head" },
      { text: "Total Amount", align: "left", value: "total" },
    ],
    syncButton: false,
  }),

  computed: {
    ...mapState(["batch"]),
  },

  watch: {
    pagination: function() {
      this.get();
    },
    batch: function(value) {
      this.get();
    },
  },
  mounted() {
    this.get();
    if (this.$auth.getServices("ACCOUNT")) this.accountServiceStatus = true;
    else this.accountServiceStatus = false;
  },

  methods: {
    get(params) {
      let extraParams = "&billLogId=" + this.$route.query.billLogId;
      let query = [null, undefined].includes(params)
        ? this.queryString(extraParams)
        : params;
      this.form.get(null, query).then(({ data }) => {
        this.totalSum = 0;
        data.data.map((data) => {
          this.totalSum += parseFloat(data.total);
        });
        this.pagination.totalItems = data.meta.total;
      });
    },
    verify() {
      if (this.accountServiceStatus) {
        //account service Enabled
        this.form.billLogId = this.$route.query.billLogId;
        this.form.assetsHeads = this.form.items.data.map((res) => {
          return {
            assets_ledger_head_id: res.map_ledger_head.asset_ledger_head_id,
            amount: res.total,
          };
        });
        this.form.incomeHeads = this.form.items.data.map((res) => {
          return {
            income_ledger_head_id: res.map_ledger_head.income_ledger_head_id,
            amount: res.total,
          };
        });
        this.$rest
          .post("api/journal-entry/bill-book", this.form)
          .then((data) => {
            this.verifyDialog = false;
            this.$events.fire("notification", { status: "success" });
            this.$router.replace({
              query: { ...this.$route.query, is_journal_entry: 2 },
            });
          })
          .catch((e) => {
            this.verifyDialog = false;
            this.$events.fire("notification", { status: "error" });
          });
      } else {
        //account service Disabled
        this.$rest
          .put("/api/billing-verify/" + this.$route.query.billLogId, {
            is_journal_entry: 2,
          })
          .then(({ data }) => {
            this.verifyDialog = false;
            this.$router.replace({
              query: { ...this.$route.query, is_journal_entry: 2 },
            });
            sthis.get();
            this.$events.fire("notification", {
              message: data.message,
              status: "success",
            });
          })
          .catch((e) => {
            this.$events.fire("notification", {
              message: data.message,
              status: "error",
            });
          });
      }
    },
    unverfiy() {
      this.$rest
        .put("/api/billing-verify/" + this.$route.query.billLogId, {
          is_journal_entry: 0,
        })
        .then(({ data }) => {
          this.unverfyDialog = false;
          this.$route.query.is_journal_entry = 0;
          this.get();
          this.$events.fire("notification", {
            message: data.message,
            status: "success",
          });
        })
        .catch((e) => {
          this.$events.fire("notification", {
            message: data.message,
            status: "error",
          });
        });
    },
  },
};
</script>
<style lang="scss">
.crRow {
  margin-left: 80px;
}
</style>
